import React from "react";
import Seo from "../../components/Seo";
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import LayoutDisableHeader from "../../components/LayoutDisableHeader";
import { graphql } from "gatsby";

const PageSuccess = ({ pageContext }) => {
  const { t } = useTranslation();
  pageContext.containerClasses = "mb-5";

  return (
    <LayoutDisableHeader>
      <Seo title={t("Paiement accepté")}>
        <body className="page-checkout-success layout-h100" />
      </Seo>

      <main className="d-flex flex-column align-items-center justify-content-between">
        <div className="container">
          <div className="bg-white p-4 p-xl-5">
            <h1 className="pt-4 pt-xl-5 text-success mb-4 mb-md-4 mb-xl-5">
              <i className="bi bi-cart-check-fill"></i> <Trans>Paiement accepté</Trans>
            </h1>
            <p className="lead">
              <Trans>Votre paiement a été confirmé, votre commande est désormais validée !</Trans>
            </p>

            <div className="alert alert-success">
              <p className="mb-2">
                <Trans>D'ici quelques minutes vous allez recevoir un e-mail contenant :</Trans>
              </p>
              <ul className="list-unstyled pl-4">
                <li>
                  <i className="bi bi-receipt"></i>{" "}
                  <Trans>
                    <b>votre facture</b> (avec ou sans TVA, selon l'état/pays de votre adresse de facturation),
                  </Trans>
                </li>
                <li>
                  <i className="bi bi-patch-check"></i>{" "}
                  <Trans>
                    <b>le lien de téléchargement</b> de votre produit avec votre <b>licence</b> utilisateur.
                  </Trans>
                </li>
              </ul>
            </div>

            <hr />

            <div className="text-left">
              <p className="text-muted">
                <i className="bi bi-arrow-return-left"></i>{" "}
                <Link to="/" title={t("Accueil")} className="text-muted">
                  <Trans>retour</Trans>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    </LayoutDisableHeader>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    imageBgPage: file(sourceInstanceName: { eq: "img_theme" }, relativePath: { eq: "checkout-success.jpg" }) {
      name
      childImageSharp {
        fluid(quality: 50, maxWidth: 3840, srcSetBreakpoints: [200, 400, 640, 860, 1280, 1536, 1920, 2048, 2732, 3840]) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default PageSuccess;
